<template>
  <div>
    <b-container class="mt-5 mb-5">
      <h1 class="fs-4 font-weight-bold mb-4">Gegevens</h1>

      <ValidationObserver ref="form" slim>
        <b-card class="mb-3">
          <h3 class="fs-5">Bedrijfsgegevens</h3>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Bedrijfsnaam</label>
              <b-form-input v-model="form.company_name" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <ValidationProvider rules="required|numeric" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">KvK-nummer</label>
              <b-form-input v-model="form.kvk_number" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }">
            <div class="mb-3">
              <label>BTW-nummer</label>
              <b-form-input v-model="form.btw_number" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
        </b-card>

        <b-card class="mb-3">
          <h3 class="fs-5">Contactgegevens</h3>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Telefoonnummer</label>
              <b-form-input v-model="form.phone" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <ValidationProvider rules="required|email" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">E-mailadres</label>
              <b-form-input v-model="form.email" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <ValidationProvider rules="email" v-slot="{ errors }">
            <div class="mb-3">
              <label>E-mailadres facturen</label>
              <b-form-input v-model="form.invoice_email" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

        </b-card>


        <b-card class="mb-3">
          <h3 class="fs-5">Adresgegevens</h3>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Straat</label>
              <b-form-input v-model="form.street" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
          
          <b-row>
            <b-col md="8">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="mb-3">
                  <label class="required">Huisnummer</label>
                  <b-form-input v-model="form.house_number" />
                  <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="4">
              <div class="mb-3">
                <label>Huisnummer toevoeging</label>
                <b-form-input v-model="form.house_number_addition" />
              </div>
            </b-col>
          </b-row>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Postcode</label>
              <b-form-input v-model="form.zipcode" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Plaats</label>
              <b-form-input v-model="form.city" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Land</label>
              <b-form-select v-model="form.country" :options="countryOptions" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
          
        </b-card>
      </ValidationObserver>

      <div class="clearfix">
        <b-button @click="saveOrganisation" variant="primary" class="float-right">
          Opslaan
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </b-button>
      </div>

    </b-container>
  </div>
</template>

<script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '@/stores/shop'
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default {
        metaInfo: {
            title: 'Gegevens',
        },
        components: {
          ValidationObserver,
          ValidationProvider,
        },
        data() {
            return {
              form: {},
              countryOptions: [
                { value: 'NL', text: 'Nederland' }
              ]
            }
        },
        computed: {},
        methods: {
          ...mapActions(useShopStore, ['setLoading']),
          getOrganisation: function() {
            this.setLoading(true);
            this.$axios.get("https://api.tixgo.nl/organisations/show")
              .then( response => {
                this.form = response.data;
                this.setLoading(false);
              })
            ;
          },
          async saveOrganisation() {
            
            let check = await this.$refs['form'].validate();

            if (!check) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              
              return;
            }

            this.$axios.put("https://api.tixgo.nl/organisations/update", this.form)
              .then( response => {
                  response;
                this.$router.push('/manage/organisation')
              })
            ;
          }
        },
        created() {
          this.getOrganisation();
        }
    }
</script>